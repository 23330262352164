module.exports = {
    language: {
      name: '中文',
    },
    about: {
      title: '我是中文',
    },
    header:{
        menu1:'网站首页',
        menu2:'关于我们',
        menu3:'加入我们',
        menu4:'扫码下载',
    },
    code:{
      title:'打开手机扫描二维码',
      subTitle:'立即下载APP'
    },
    index:{
      title1:'全球电商一站式采购',
      title2:'一站式跨境电商平台',
      title3:'Meta2032mail跨境电商是一个专业的电子商务平台，旨在为全球客户提供一站式的跨境电商采购服务。我们的目标是通过整合全球优质的商品资源，帮助客户更便捷地进行国际贸易',
      title4:'小生意全球做就来',
      title5:'我们欢迎全球各地的企业和个人加入我们的平台，共同发展跨境电商产业。',
      title6:' 我们将不断提高我们的服务水平，为客户提供更优质的服务。',
      title7:'马上加入',
      title8:'立即下载使用',
      title9:'开启全球购物之旅',
      title10:'一支穿云箭',
      title11:'全球生意来相见',
      title12:'我们的平台拥有全球多个国家的云仓储，使得商品的采购和存储更加方便快捷。',
      title13:'此外，我们还拥有一支专业的团队，负责产品的采购、检测、物流等全流程服务。'
    }
  };