<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
  name: 'headerCom',
  props: {
    index: Number
  },
   directives: {
    ClickOutside
  },
  data () {
    return {

    }
  },
  mounted(){
     localStorage.setItem('languageStorage','zh')
  }

}
</script>

<style>
*{
  padding: 0;
  margin: 0;
 box-sizing: border-box;
}
.align-center{
  display: flex;
  align-items: center;
}
.center{
   display: flex;
  align-items: center;
  justify-content: center;
}
.flex{
  display: flex;
}
.index_container{
  padding-top: 96px;
}
</style>
