module.exports = {
    language: {
      name: '英文',
    },
    about: {
      title: 'I am English',
    },
    header:{
        menu1:'Index',
        menu2:'About',
        menu3:'Join us',
        menu4:'Download app',
    },
    code:{
      title:'Scan QR code',
      subTitle:'Download app'
    },
    index:{
      title1:'Global e-commerce one-stop procurement',
      title2:'One stop cross-border e-commerce platform',
      title3:'Meta2032mail Cross-border e-commerce is a professional e-commerce platform,It aims to provide global customers with one-stop cross-border e-commerce procurement services. Our goal is to help customers conduct international trade more conveniently by integrating high-quality global commodity resources',
      title4:'Small business can be done globally',
      title5:'We welcome enterprises and individuals from all over the world to join our platform to jointly develop the cross-border e-commerce industry.',
      title6:'',
      title7:'Join now',
      title8:'Download now for use',
      title9:'Start a global shopping trip',
      title10:'A cloud piercing arrow',
      title11:'Global business to meet',
      title12:'Our platform has cloud warehouses in multiple countries around the world, making the procurement and storage of goods more convenient and efficient.',
      title13:'In addition, we also have a professional team responsible for the entire process of product procurement, testing, logistics, and other services.'
    }
  };